import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage, db } from "../firebase";
import moment from "moment";
import * as pdfjsLib from "pdfjs-dist";
import { collection, getDocs } from "firebase/firestore";
import imageCompression from "browser-image-compression"; // Import the image compression library

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`;

export const pdfPageToImage = async (pdfBytes: Uint8Array) => {
  const loadingTask = pdfjsLib.getDocument({ data: pdfBytes });
  const pdf = await loadingTask.promise;
  const page = await pdf.getPage(1);

  const viewport = page.getViewport({ scale: 2 });
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  if (context) {
    await page.render({ canvasContext: context, viewport }).promise;
  }

  return canvas.toDataURL("image/png");
};

export const dataURLToBlob = (dataURL: string) => {
  const byteString = atob(dataURL.split(",")[1]);
  const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
  const buffer = new ArrayBuffer(byteString.length);
  const data = new Uint8Array(buffer);

  for (let i = 0; i < byteString.length; i++) {
    data[i] = byteString.charCodeAt(i);
  }

  return new Blob([buffer], { type: mimeString });
};

export const processAndUploadFile = async (
  file: File,
  userId: string,
  invoiceNumber: number,
  date: string | null,
  type: "purchase" | "sales" | "expense"
): Promise<string> => {
  if (!date) {
    date = "unknown_date";
  }

  const collectionPath =
    type === "purchase"
      ? "purchaseInvoices"
      : type === "sales"
      ? "salesInvoices"
      : "expenseInvoices";

  const fileName = `Invoice_${invoiceNumber}_${date
    .replace("/", "_")
    .replace("/", "_")}`;

  const fileRef = ref(storage, `${collectionPath}/${userId}/${fileName}`);

  // Compress the image file
  const options = {
    maxSizeMB: 2,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
    maxIteration: 10,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    await uploadBytes(fileRef, compressedFile);
  } catch (error) {
    console.error("Compression failed, using original file:", error);
    await uploadBytes(fileRef, file);
  }

  // Get the download URL of the uploaded file
  return await getDownloadURL(fileRef);
};

export const extractDate = (text: string): string | null => {
  console.log(text);

  const datePatterns = [
    /(\d{2}\/\d{2}\/\d{4})(?=\s|$)/, // DD/MM/YYYY
    /(\d{2}\.\d{2}\.\d{4})(?=\s|$)/, // DD.MM.YYYY
    /(\d{2}-\d{2}-\d{4})(?=\s|$)/, // DD-MM-YYYY
    /(\d{4}\/\d{2}\/\d{2})(?=\s|$)/, // YYYY/MM/DD
    /(\d{4}-\d{2}-\d{2})(?=\s|$)/, // YYYY-MM-DD
    /(\d{2}\/\d{2}\/\d{2})(?=\s|$)/, // MM/DD/YY
    /(\d{1,2} \w+ \d{4})(?=\s|$)/, // D MMMM YYYY
    /(\d{1,2} \w{3} \d{4})(?=\s|$)/, // D MMM YYYY
    /(\d{4} \w+ \d{1,2})(?=\s|$)/, // YYYY MMMM D
    /(\d{4} \w{3} \d{1,2})(?=\s|$)/, // YYYY MMM D
    /(\d{2}-\w{3}-\d{2})(?=\s|$)/, // DD-MMM-YY
    /(\d{1,2} \w{3}, \d{4})(?=\s|$)/, // D MMM, YYYY
    /(\w+ \d{1,2}(?:st|nd|rd|th)?,? \d{4})(?=\s|$)/, // MMMM Dst, YYYY
    /(\d{1,2}(?:st|nd|rd|th) \w+ \d{4})(?=\s|$)/, // Dst MMMM YYYY
    /(\d{1,2}(?:st|nd|rd|th) \w{3}, \d{4})(?=\s|$)/, // Dst MMM, YYYY
    /(\d{1,2}-\w{3}-\d{4})(?=\s|$)/, // D-MMM-YYYY
    /(\d{1,2}(?:st|nd|rd|th) \w+ \d{4})(?=\s|$)/, // Dst MMMM, YYYY
    /(\d{1,2}(?:st|nd|rd|th) \w+, \d{4})(?=\s|$)/, // Dst MMMM, YYYY with comma
    /(\d{1,2} \w{3} \d{2})(?=\s|$)/, // D MMM YY
    /(\d{2}, \w{3} \d{4})(?=\s|$)/, // DD, MMM YYYY (new pattern)
  ];

  const formats = [
    "DD/MM/YYYY",
    "DD.MM.YYYY",
    "DD-MM-YYYY",
    "YYYY/MM/DD",
    "YYYY-MM-DD",
    "MM/DD/YY",
    "D MMMM YYYY",
    "D MMM YYYY",
    "MMMM D, YYYY", // MMMM D, YYYY
    "YYYY MMMM D",
    "YYYY MMM D",
    "DD-MMM-YY",
    "D MMM, YYYY",
    "Do MMMM YYYY",
    "Do MMM, YYYY",
    "D-MMM-YYYY",
    "Do MMMM, YYYY",
    "MMMM Do, YYYY", // MMMM Do, YYYY
    "MMMM D YYYY", // MMMM D YYYY
    "DD MMM YY", // Added format for D MMM YY
    "DD, MMM YYYY", // Added format for DD, MMM YYYY (new format)
  ];

  for (const pattern of datePatterns) {
    const match = text.match(pattern);
    if (match) {
      const dateStr = match[0];
      for (const format of formats) {
        const date = moment(dateStr, format, true);
        if (date.isValid()) {
          return date.format("DD/MM/YYYY");
        }
      }
    }
  }
  return null;
};

export const extractTotals = (
  text: string
): {
  subtotal: number | null;
  vatTotal: number | null;
  total: number | null;
} => {
  const subtotalPattern = /subtotal\s*[:€$£]\s*([\d,]+\.\d{2})/i;
  const vatPattern = /vat\s*[:€$£]\s*([\d,]+\.\d{2})/i;
  const totalPattern = /total\s*[:€$£]\s*([\d,]+\.\d{2})/i;

  const subtotalMatch = text.match(subtotalPattern);
  const vatMatch = text.match(vatPattern);
  const totalMatch = text.match(totalPattern);

  const parseAmount = (amountStr: string | undefined): number | null => {
    if (!amountStr) return null;
    return parseFloat(amountStr.replace(/,/g, ""));
  };

  let parsedValues = {
    subtotal: parseAmount(subtotalMatch ? subtotalMatch[1] : undefined),
    vatTotal: parseAmount(vatMatch ? vatMatch[1] : undefined),
    total: parseAmount(totalMatch ? totalMatch[1] : undefined),
  };

  const VAT_RATE = 0.18; // Define the VAT rate as 18%

  // If only the total is found, calculate the subtotal and VAT based on the total
  if (
    parsedValues.total !== null &&
    (parsedValues.subtotal === null || parsedValues.vatTotal === null)
  ) {
    parsedValues.subtotal = parsedValues.total / (1 + VAT_RATE);
    parsedValues.vatTotal = parsedValues.total - parsedValues.subtotal;
  }

  if (!parsedValues.subtotal || !parsedValues.vatTotal || !parsedValues.total) {
    const euroAmounts = text.match(/[€$£]\s*([\d,]+\.\d{2})/g);
    const allAmounts = euroAmounts
      ? euroAmounts.map((amount) => parseFloat(amount.replace(/[€$£\s,]/g, "")))
      : [];

    if (allAmounts.length === 0) {
      const decimalAmounts = text.match(/([\d,]+\.\d{2})/g);
      allAmounts.push(
        ...(decimalAmounts
          ? decimalAmounts.map((amount) => parseFloat(amount.replace(/,/g, "")))
          : [])
      );
    }

    if (allAmounts.length > 0) {
      allAmounts.sort((a, b) => b - a); // Sort in descending order
      parsedValues.total = parsedValues.total || allAmounts[0];
      parsedValues.subtotal = parsedValues.subtotal || allAmounts[1] || null;
      if (parsedValues.subtotal && parsedValues.total) {
        parsedValues.vatTotal =
          parsedValues.vatTotal ||
          parseFloat((parsedValues.total - parsedValues.subtotal).toFixed(2));
      }
    }
  }

  return parsedValues;
};

// Function to find client name from the text
export const findClientName = async (
  text: string,
  userId: string
): Promise<string> => {
  try {
    const clientDocs = await getDocs(
      collection(db, "clients", userId, "userClients")
    );

    const clients = clientDocs.docs.map((doc) => ({
      original: doc.data().name,
      lower: doc.data().name.toLowerCase().trim(),
    }));

    const normalizedText = text.toLowerCase().trim();

    const matchedClient = clients.find((client) =>
      normalizedText.includes(client.lower)
    );

    return matchedClient && matchedClient.original
      ? matchedClient.original
      : "Unknown";
  } catch (error) {
    return "Unknown";
  }
};

// Example usage of the extraction functions
export const processInvoiceText = async (text: string, userId: string) => {
  const date = extractDate(text);
  const totals = extractTotals(text);
  const clientName = await findClientName(text, userId);
  return { date, ...totals, clientName };
};
