import React from "react";
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface FilterControlsProps {
  yearFilter: string;
  setYearFilter: (year: string) => void;
  monthFilter: string;
  setMonthFilter: (month: string) => void;
  quarterFilter: string;
  setQuarterFilter: (quarter: string) => void;
}

const FilterControls: React.FC<FilterControlsProps> = ({
  yearFilter,
  setYearFilter,
  monthFilter,
  setMonthFilter,
  quarterFilter,
  setQuarterFilter,
}) => {
  return (
    <Box display="flex" justifyContent="space-between" mb={2}>
      <FormControl variant="outlined" style={{ minWidth: 120 }}>
        <InputLabel>Year</InputLabel>
        <Select
          value={yearFilter}
          onChange={(e) => setYearFilter(e.target.value as string)}
          label="Year"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {[...Array(10).keys()].map((i) => {
            const year = new Date().getFullYear() - i;
            return (
              <MenuItem key={year} value={year.toString()}>
                {year}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ minWidth: 120 }}>
        <InputLabel>Month</InputLabel>
        <Select
          value={monthFilter}
          onChange={(e) => setMonthFilter(e.target.value as string)}
          label="Month"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {Array.from({ length: 12 }, (_, i) => (
            <MenuItem key={i + 1} value={(i + 1).toString()}>
              {new Date(0, i).toLocaleString("default", { month: "long" })}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" style={{ minWidth: 120 }}>
        <InputLabel>Quarter</InputLabel>
        <Select
          value={quarterFilter}
          onChange={(e) => setQuarterFilter(e.target.value as string)}
          label="Quarter"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value="1">Q1</MenuItem>
          <MenuItem value="2">Q2</MenuItem>
          <MenuItem value="3">Q3</MenuItem>
          <MenuItem value="4">Q4</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterControls;
