import React, { useState, useCallback } from "react";
import { auth, db } from "../firebase";
import Swal from "sweetalert2";
import { useDropzone } from "react-dropzone";
import { processFile } from "../utils/extraction"; // Import the processFile function

interface InvoiceUploadProps {
  type: "purchase" | "sales" | "expense";
  onUploadSuccess: () => void;
}

const InvoiceUpload: React.FC<InvoiceUploadProps> = ({
  type,
  onUploadSuccess,
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const uploadFiles = async () => {
    if (files.length === 0) return;

    const user = auth.currentUser;
    if (!user) {
      Swal.fire("Error", "No user logged in", "error");
      return;
    }

    setProcessing(true);

    try {
      const updatedFiles = [...files];

      for (const [index, file] of files.entries()) {
        try {
          await processFile(file, user, db, type);

          // Remove the file from the list after successful upload
          updatedFiles.splice(index, 1);
          setFiles([...updatedFiles]);

          console.log(`File ${file.name} uploaded successfully.`);
        } catch (error) {
          console.error(`Error processing file ${file.name}:`, error);
          Swal.fire("Error", `Error processing file ${file.name}`, "error");
        }
      }

      Swal.fire("Success", "Invoices uploaded successfully", "success");
      onUploadSuccess(); // Call the function to close the modal
    } catch (error) {
      console.error("Error uploading files:", error);
      Swal.fire("Error", "Error uploading files", "error");
    } finally {
      setProcessing(false);
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">
        {type === "purchase"
          ? "Upload Purchase Invoice"
          : type === "sales"
          ? "Upload Sales Invoice"
          : "Upload Expense Invoice"}
      </h2>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="border-dashed border-2 border-gray-400 p-4 mb-4"
      >
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <button
        onClick={uploadFiles}
        disabled={processing}
        className="bg-blue-500 text-white p-2 w-full"
      >
        {processing ? "Processing..." : "Upload"}
      </button>
      <div className="mt-4">
        {files.length > 0 && (
          <ul>
            {files.map((file, index) => (
              <li key={index}>{file.name}</li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default InvoiceUpload;
