// src/components/ForgotPassword.tsx
import React, { useState } from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");

  const resetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        Swal.fire("Success", "Password reset email sent!", "success");
      })
      .catch((error) => Swal.fire("Error", error.message, "error"));
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Forgot Password</h2>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        className="border p-2 mb-4 w-full"
      />
      <button
        onClick={resetPassword}
        className="bg-blue-500 text-white p-2 w-full mb-2"
      >
        Reset Password
      </button>
      <div className="flex justify-between mt-4">
        <Link to="/signin" className="text-blue-500">
          Back to Sign In
        </Link>
        <Link to="/signup" className="text-blue-500">
          Don't have an account? Sign Up
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
