import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../firebase";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Box,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LoginIcon from "@mui/icons-material/Login";
import PersonIcon from "@mui/icons-material/Person";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
const Navigation: React.FC = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const user = auth.currentUser;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const navLinks = [
    {
      to: "/",
      label: "Home",
      icon: <HomeIcon />,
      visible: true,
    },
    {
      to: "/purchases",
      label: "Purchase Invoices",
      icon: <ShoppingCartIcon />,
      visible: !!user,
    },
    {
      to: "/sales",
      label: "Sales Invoices",
      icon: <PointOfSaleIcon />,
      visible: !!user,
    },
    {
      to: "/expenses",
      label: "Expense Invoices",
      icon: <ReceiptIcon />,
      visible: !!user,
    },
    {
      to: "/generator",
      label: "Invoice Generator",
      icon: <FileCopyIcon />,
      visible: !!user,
    },
    {
      to: "/clients",
      label: "Clients List",
      icon: <PersonIcon />,
      visible: !!user,
    },
    {
      to: "/signin",
      label: "Sign In",
      icon: <LoginIcon />,
      visible: !user,
    },
  ];

  const drawerItems = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {navLinks
          .filter((link) => link.visible)
          .map((link) => (
            <ListItem button component={Link} to={link.to} key={link.to}>
              <ListItemIcon>{link.icon}</ListItemIcon>
              <ListItemText primary={link.label} />
            </ListItem>
          ))}
        {user && (
          <>
            <Divider />
            <ListItem
              button
              onClick={() => {
                auth.signOut();
              }}
            >
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary="Sign Out" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: "#024798" }}>
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
            <Link to="/" className="hover:underline text-white">
              <img src="../assets/invo.jpg" alt="DSM Logo" className="h-10" />
            </Link>
          </Box>

          <Box
            sx={{
              display: { xs: "none", md: "flex" },
              color: "#024798",
              alignItems: "center",
            }}
          >
            {navLinks
              .filter((link) => link.visible && link.to !== "/signin")
              .map((link) => (
                <Link
                  key={link.to}
                  to={link.to}
                  className="mr-4 hover:underline text-white"
                >
                  {link.label}
                </Link>
              ))}
            {user ? (
              <button
                onClick={() => auth.signOut()}
                className="bg-red-500 px-4 py-2 rounded hover:bg-red-700 text-white"
              >
                Sign Out
              </button>
            ) : (
              <Link to="/signin" className="hover:underline text-white">
                Sign In
              </Link>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      {isMobile && (
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawerItems}
        </Drawer>
      )}
    </>
  );
};

export default Navigation;
