import axios from "axios";

export const processInvoiceText = async (
  text: string,
  clientName: string,
  apiKey: string
) => {
  const apiUrl = "https://api.openai.com/v1/chat/completions"; // Correct API endpoint

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${apiKey}`,
  };
  console.log(clientName, text);
  const prompt = `Extract the following information from this invoice text. For monetary totals, exclude the currency, format them with two decimal places using a period (.) and no commas. Return the category exactly as "Category:" NEVER MAKE YOUR OWN CALCULATIONS FOR TOTALS:

  1. Supplier Name (Ensure it includes terms like "Limited", "Ltd", "PLC", or a government institution like Transport Malta or similar. it will never be the client name which is "${clientName}" if you think its  "${clientName}" then thats wrong . The supplier name should be logical, considering its position—typically mentioned multiple times, often at the beginning of the document.)
  2. Invoice Date (in any format, and return it in the format DD/MM/YYYY)
  3. Invoice Number (typically a single string without spaces, ignore any leading text that isn't part of the invoice number itself. It is usually labeled as "Invoice Number" or "Reference Number" and may start with "INV" or a similar prefix)
  4. Total Amount before VAT (extract exactly as stated in the invoice, without any calculations, and without including commas or currency symbols) (this will normally be the final total minus the vat amount, but if not listed dont calculate)
  5. Total VAT amount (extract exactly as stated, without commas or currency symbols)
  6. Total Amount including VAT (extract exactly as stated, without commas or currency symbols. if its not there use the total amount before VAT or subtotal if vat or tax is not there)
  7. Categorize the items in the invoice under a general category, and return it as "Category: [Category Name]".
  
  Ensure no special characters are included in the output, such as currency symbols.
  
  Here is the text: "${text}"`;

  const requestBody = {
    model: "gpt-4o-mini", // or any model you have access to
    messages: [{ role: "user", content: prompt }],
  };

  try {
    const { data } = await axios.post(apiUrl, requestBody, { headers });
    const extractedData = data.choices[0].message.content.replace(/\*/g, "");
    console.log(extractedData);

    const date =
      extractedData.match(/Invoice Date:\s*([\d/]+)/)?.[1] || "Unknown";

    const totals = {
      subtotal: parseFloat(
        (
          extractedData.match(/Total Amount before VAT:\s*([\d,.]+)/)?.[1] ||
          "0"
        ).replace(/,/g, "")
      ),
      vatTotal: parseFloat(
        (
          extractedData.match(/Total VAT amount:\s*([\d,.]+)/)?.[1] || "0"
        ).replace(/,/g, "")
      ),
      total: parseFloat(
        (
          extractedData.match(/Total Amount including VAT:\s*([\d,.]+)/)?.[1] ||
          extractedData.match(/Total Amount before VAT:\s*([\d,.]+)/)?.[1] ||
          "0"
        ).replace(/,/g, "")
      ),
    };

    const supplierName = (
      extractedData.match(/Supplier Name:\s*([\w\s.]+)/)?.[1] || "Unknown"
    )
      .replace(/Invoice Date.*$/i, "") // Remove anything after "Invoice Date"
      .replace(/\s*\d+\.\s*$/, "") // Remove trailing "2." or similar patterns
      .trim(); // Trim any extra spaces

    const invoiceNumber =
      extractedData.match(/Invoice Number:\s*([\w-]+)/)?.[1] || "Unknown";
    const category =
      extractedData.match(/Category:\s*([\w ]+)/)?.[1] || "Uncategorized";

    return { date, totals, supplierName, invoiceNumber, category };
  } catch (error) {
    console.error("Error processing invoice:", error);
    return null;
  }
};
