import React from "react";
import { Modal, Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Invoice } from "../Invoices";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { auth } from "../../firebase";

interface ViewInvoiceModalProps {
  open: boolean;
  onClose: () => void;
  invoice: Invoice | null;
  onDownload: (invoice: Invoice) => void;
}

const ViewInvoiceModal: React.FC<ViewInvoiceModalProps> = ({
  open,
  onClose,
  invoice,
  onDownload,
}) => {
  console.log(invoice);

  if (!invoice) return null;
  console.log(invoice.fileUrl, auth.currentUser?.uid);
  const getFileNameWithExtension = (fileName: string) => {
    console.log(fileName);

    if (!fileName.includes(".")) {
      return `${fileName}.png`;
    }
    return fileName;
  };
  const downloadFile = async (fileUrl: string, fileName: string) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = getFileNameWithExtension(fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Failed to download file", error);
    }
  };
  const onCloseModal = (event: {}, reason: string) => {
    console.log(event, reason);
    if (reason) {
      onClose();
    }
  };
  const isPdf = invoice.fileUrl.includes(".pdf");
  console.log(invoice.fileUrl);

  return (
    <Modal
      open={open}
      onClose={(event, reason) => onCloseModal(event, reason)}
      aria-labelledby="invoice-modal-title"
      aria-describedby="invoice-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: {
            xs: "90%", // 90% width for extra-small screens (mobile)
            sm: "80%", // 80% width for small screens
            md: 600, // Fixed width for medium and larger screens
          },
          maxHeight: "90vh", // Ensure the modal does not exceed the viewport height
          overflowY: "auto", // Enable scrolling for long content
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
        onClick={(e) => e.stopPropagation()} // Prevent modal close when clicking inside the content
      >
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography id="invoice-modal-title" variant="h6" component="h2">
            Invoice Details
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box mb={2}>
          <Typography variant="body1">
            <strong>Invoice Number:</strong> {invoice.invoiceNumber}
          </Typography>
          <Typography variant="body1">
            <strong>Date:</strong> {invoice.date}
          </Typography>
          <Typography variant="body1">
            <strong>Invoice Name:</strong> {invoice.name}
          </Typography>
          <Typography variant="body1">
            <strong>Client Name:</strong> {invoice.clientName}
          </Typography>
          <Typography variant="body1">
            <strong>SubTotal:</strong> {invoice.subtotal}
          </Typography>
          <Typography variant="body1">
            <strong>Tax:</strong> {invoice.vatTotal}
          </Typography>
          <Typography variant="body1">
            <strong>Amount:</strong> {invoice.total}
          </Typography>
        </Box>
        {isPdf ? (
          <iframe
            src={invoice.fileUrl}
            title="Invoice PDF"
            width="100%"
            height="500px"
            style={{ border: "none" }}
          ></iframe>
        ) : (
          <Zoom>
            <img
              src={invoice.fileUrl}
              alt={invoice.fileName}
              className="w-full h-auto mb-4"
              onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking the zoomed image
            />
          </Zoom>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={() => downloadFile(invoice.fileUrl, invoice.fileName)}
        >
          Download
        </Button>
      </Box>
    </Modal>
  );
};

export default ViewInvoiceModal;
