import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase";
import SignIn from "./components/Auth/SignIn";
import Navigation from "./components/Navigation";
import { User } from "firebase/auth";
import SignUp from "./components/Auth/SignUp";
import ForgotPassword from "./components/Auth/ForgotPassword";
import PurchaseInvoices from "./components/PurchaseInvoices";
import SalesInvoices from "./components/SalesInvoices";
import Home from "./components/Home";
import ExpenseInvoices from "./components/ExpenseInvoices";
import ClientManager from "./components/ClientManager";
import InvoiceGenerator from "./components/InvoiceGenerator";
import LoadingScreen from "./components/LoadingScreen"; // Import the LoadingScreen component

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(() => {
    // Initialize user from localStorage if available
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        // Store the user in localStorage
        localStorage.setItem("user", JSON.stringify(user));
      } else {
        // Remove the user from localStorage if not authenticated
        localStorage.removeItem("user");
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Simulate page loading behind the loading screen
    setTimeout(() => {
      setLoading(false);
    }, 4000); // Adjust the time as per the need
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-gray-100 flex flex-col relative">
        <Navigation />
        <main className="flex-1 w-full max-w-4xl mx-auto p-4">
          <Routes>
            <Route
              path="/signin"
              element={user ? <Navigate to="/" /> : <SignIn />}
            />
            <Route
              path="/signup"
              element={user ? <Navigate to="/" /> : <SignUp />}
            />
            <Route
              path="/forgot-password"
              element={user ? <Navigate to="/" /> : <ForgotPassword />}
            />
            <Route
              path="/purchases"
              element={user ? <PurchaseInvoices /> : <Navigate to="/signin" />}
            />
            <Route
              path="/generator"
              element={user ? <InvoiceGenerator /> : <Navigate to="/signin" />}
            />
            <Route
              path="/sales"
              element={user ? <SalesInvoices /> : <Navigate to="/signin" />}
            />
            <Route
              path="/expenses"
              element={user ? <ExpenseInvoices /> : <Navigate to="/signin" />}
            />
            <Route
              path="/clients"
              element={user ? <ClientManager /> : <Navigate to="/signin" />}
            />
            <Route
              path="/home"
              element={user ? <Home /> : <Navigate to="/signin" />}
            />
            <Route
              path="/"
              element={user ? <Home /> : <Navigate to="/signin" />}
            />
          </Routes>
        </main>
        <footer className="bg-dsmblue w-full p-4 text-white text-center">
          <a
            href="http://www.dsmcomms.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            DSM Communications
          </a>{" "}
          &copy; 2024 Invoice Manager
        </footer>
        {loading && <LoadingScreen onFinish={() => setLoading(false)} />}
      </div>
    </Router>
  );
};

export default App;
