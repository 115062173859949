import InvoicePage from "./InvoiceGenerator/components/InvoicePage";
import { Invoice } from "./InvoiceGenerator/data/types";

function InvoiceGenerator() {
  const savedInvoice = window.localStorage.getItem("invoiceData");
  let data = null;

  try {
    if (savedInvoice) {
      data = JSON.parse(savedInvoice);
    }
  } catch (_e) {}

  const onInvoiceUpdated = (invoice: Invoice) => {
    window.localStorage.setItem("invoiceData", JSON.stringify(invoice));
  };

  return (
    <div className="invoiceGenerator">
      <h1 className="app__title">Invoice Generator</h1>
      <InvoicePage data={data} onChange={onInvoiceUpdated} />
    </div>
  );
}

export default InvoiceGenerator;
