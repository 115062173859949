import React from "react";
import { Button, Box, Grid } from "@mui/material";

interface InvoiceActionsProps {
  selectedInvoices: string[];
  onRescan: () => void;
  onEdit: () => void;
  onDelete: () => void;
  onDownload: () => void;
}

const InvoiceActions: React.FC<InvoiceActionsProps> = ({
  selectedInvoices,
  onRescan,
  onEdit,
  onDelete,
  onDownload,
}) => {
  const singleSelection = selectedInvoices.length === 1;
  const multipleSelection = selectedInvoices.length > 0;

  return (
    <Box mb={2}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onRescan}
            disabled={!multipleSelection}
          >
            Rescan Selected
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onDownload}
            disabled={!multipleSelection}
          >
            Download
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={onEdit}
            disabled={!singleSelection}
          >
            Edit Selected
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={onDelete}
            disabled={!multipleSelection}
          >
            Delete Selected
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoiceActions;
