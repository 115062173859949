// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBzkKXyMqdA-lYHnh3xLK_9tgJK7m6JAdc",
  authDomain: "invoice-6a201.firebaseapp.com",
  projectId: "invoice-6a201",
  storageBucket: "invoice-6a201.appspot.com",
  messagingSenderId: "781638563711",
  appId: "1:781638563711:web:e2b5e735d8704296884c4a",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

export { db, auth, storage };
