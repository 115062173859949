export const extractTextFromImage = async (
  imageFile: File
): Promise<string> => {
  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve((reader.result as string).split(",")[1]);
      reader.onerror = (error) => reject(error);
    });
  };

  try {
    // Convert the image file to base64
    const base64Image = await convertToBase64(imageFile);

    // Send the base64 image to the Firebase Cloud Function
    const response = await fetch(
      "https://europe-west1-invoice-6a201.cloudfunctions.net/extractTextFromImage",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ image: base64Image }),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to extract text from image");
    }

    const data = await response.json();

    // Return the extracted text
    return data.text;
  } catch (error) {
    console.error("Error extracting text:", error);
    throw error;
  }
};
