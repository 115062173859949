import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

interface Client {
  id: string;
  name?: string;
  vatNumber?: string;
  address?: string;
  contactInfo?: string;
}

const ClientManager: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [clientDetails, setClientDetails] = useState<Partial<Client>>({});
  const [modalOpen, setModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const fetchClients = async () => {
    const user = auth.currentUser;
    if (!user) return;
    const userId = user.uid;
    const q = collection(db, "clients", userId, "userClients");
    const querySnapshot = await getDocs(q);
    setClients(
      querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as Client))
    );
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleAddClient = async () => {
    const user = auth.currentUser;
    if (!user) return;
    const userId = user.uid;
    await addDoc(collection(db, "clients", userId, "userClients"), {
      name: clientDetails.name || "",
      vatNumber: clientDetails.vatNumber || "",
      address: clientDetails.address || "",
      contactInfo: clientDetails.contactInfo || "",
    });
    setClientDetails({});
    fetchClients();
    setModalOpen(false);
  };

  const handleEditClient = (client: Client) => {
    setClientDetails(client);
    setIsEditing(true);
    setModalOpen(true);
  };

  const handleSaveEdit = async () => {
    if (!clientDetails.id) return;
    const user = auth.currentUser;
    if (!user) return;
    const userId = user.uid;
    const clientRef = doc(
      db,
      "clients",
      userId,
      "userClients",
      clientDetails.id
    );

    await updateDoc(clientRef, {
      name: clientDetails.name || "",
      vatNumber: clientDetails.vatNumber || "",
      address: clientDetails.address || "",
      contactInfo: clientDetails.contactInfo || "",
    });

    setModalOpen(false);
    setClientDetails({});
    setIsEditing(false);
    fetchClients();
  };

  const deleteClient = async (id: string) => {
    const user = auth.currentUser;
    if (!user) return;
    const userId = user.uid;
    await deleteDoc(doc(db, "clients", userId, "userClients", id));
    fetchClients();
  };

  const handleModalOpen = () => {
    setClientDetails({});
    setIsEditing(false);
    setModalOpen(true);
  };

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleModalOpen}>
        Add Client
      </Button>
      <List>
        {clients.map((client) => (
          <ListItem
            key={client.id}
            secondaryAction={
              <>
                <IconButton
                  edge="end"
                  aria-label="edit"
                  onClick={() => handleEditClient(client)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => deleteClient(client.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            }
          >
            <ListItemText
              primary={client.name}
              secondary={`Vat: ${client.vatNumber || ""}, Address:${
                client.address || ""
              }, Contact Info: ${client.contactInfo || ""}`}
            />
          </ListItem>
        ))}
      </List>

      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="client-modal-title"
        aria-describedby="client-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="client-modal-title" variant="h6" component="h2">
            {isEditing ? "Edit Client" : "Add Client"}
          </Typography>
          <TextField
            label="Client Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientDetails.name || ""}
            onChange={(e) =>
              setClientDetails((prev) => ({ ...prev, name: e.target.value }))
            }
          />
          <TextField
            label="VAT Number"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientDetails.vatNumber || ""}
            onChange={(e) =>
              setClientDetails((prev) => ({
                ...prev,
                vatNumber: e.target.value,
              }))
            }
          />
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientDetails.address || ""}
            onChange={(e) =>
              setClientDetails((prev) => ({ ...prev, address: e.target.value }))
            }
          />
          <TextField
            label="Contact Information"
            variant="outlined"
            fullWidth
            margin="normal"
            value={clientDetails.contactInfo || ""}
            onChange={(e) =>
              setClientDetails((prev) => ({
                ...prev,
                contactInfo: e.target.value,
              }))
            }
          />
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={isEditing ? handleSaveEdit : handleAddClient}
            >
              {isEditing ? "Save" : "Add"}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ClientManager;
