import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const Home: React.FC = () => {
  const [userName, setUserName] = useState<string | null>(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // You can customize this to get the user's display name or email
        // console.log(user);

        setUserName(user.displayName || user.email);
      } else {
        setUserName(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <div className="text-center mt-8 px-4">
      <h1 className="text-4xl font-bold mb-4">Welcome to Invo</h1>
      {userName && <h2 className="text-2xl mb-4">Hello, {userName}!</h2>}
      <p className="mb-8">
        Manage your purchase, sales, and expense invoices efficiently.
      </p>
      <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
        <Link
          to="/purchases"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Purchase Invoices
        </Link>
        <Link
          to="/sales"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Sales Invoices
        </Link>
        <Link
          to="/expenses"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
        >
          Expense Invoices
        </Link>
      </div>
    </div>
  );
};

export default Home;
