import styles from "./styles";

// Define the type for style objects (you can use any object type here)
type StyleObject = { [key: string]: any };

const compose = (classes: string): StyleObject => {
  const css: StyleObject = {};

  const classesArray: string[] = classes.replace(/\s+/g, " ").split(" ");

  classesArray.forEach((className) => {
    if (typeof styles[className] !== "undefined") {
      Object.assign(css, styles[className]);
    }
  });

  return css;
};

export default compose;
