import React, { FC, useState, useEffect, Key } from "react";
import { Invoice, ProductLine } from "../data/types";
import { initialInvoice, initialProductLine } from "../data/initialData";
import EditableInput from "./EditableInput";
import EditableSelect from "./EditableSelect";
import EditableTextarea from "./EditableTextarea";
import EditableCalendarInput from "./EditableCalendarInput";
import EditableFileImage from "./EditableFileImage";
import countryList from "../data/countryList";
import Document from "./Document";
import Page from "./Page";
import View from "./View";
import Text from "./Text";
import { Font } from "@react-pdf/renderer";
import Download from "./DownloadPDF";
import { format } from "date-fns/format";
import { Button, Modal, Box, TextareaAutosize } from "@mui/material";

Font.register({
  family: "Nunito",
  fonts: [
    { src: "https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf" },
    {
      src: "https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf",
      fontWeight: 600,
    },
  ],
});

interface Props {
  data?: Invoice;
  pdfMode?: boolean;
  onChange?: (invoice: Invoice) => void;
}

const InvoicePage: FC<Props> = ({ data, pdfMode, onChange }) => {
  const [invoice, setInvoice] = useState<Invoice>(
    data ? { ...data } : { ...initialInvoice }
  );
  const [subTotal, setSubTotal] = useState<number>();
  const [saleTax, setSaleTax] = useState<number>();
  const [customColumns, setCustomColumns] = useState<string[]>([]);
  const [inputData, setInputData] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);

  const handleAddCustomColumn = () => {
    const columnName = prompt("Enter the column name:");
    if (columnName) {
      setCustomColumns([...customColumns, columnName]);
      setInvoice((prevInvoice) => ({
        ...prevInvoice,
        productLines: prevInvoice.productLines.map((line) => ({
          ...line,
          [columnName]: "",
        })),
      }));
    }
  };

  const handleDeleteCustomColumn = (columnName: string) => {
    setCustomColumns(customColumns.filter((col) => col !== columnName));
    setInvoice((prevInvoice) => ({
      ...prevInvoice,
      productLines: prevInvoice.productLines.map((line) => {
        const newLine = { ...line } as Partial<ProductLine> & {
          [key: string]: any;
        };
        delete newLine[columnName];
        return newLine as ProductLine;
      }),
    }));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputData(event.target.value);
  };

  const handleImportData = () => {
    const rows = inputData.split("\n").filter((row) => row.trim() !== "");
    const newProductLines = rows.map((row) => {
      const columns = row.split("\t");
      const newProductLine: ProductLine = { ...initialProductLine };
      columns.forEach((col, index) => {
        if (index === 0) newProductLine.description = col;
        if (index >= 1 && index <= customColumns.length) {
          newProductLine[customColumns[index - 1]] = col;
        }
        if (index === customColumns.length + 1) newProductLine.quantity = col;
        if (index === customColumns.length + 2) newProductLine.rate = col;
      });
      return newProductLine;
    });

    const currentIndex = 0; // Replace 0 with the actual index value
    setInvoice((prevInvoice) => {
      const productLines = [...prevInvoice.productLines];
      productLines.splice(currentIndex, 1, ...newProductLines);
      return { ...prevInvoice, productLines };
    });
    setOpenModal(false);
  };

  const dateFormat = "dd, MMM yyyy";
  let invoiceDate;
  let invoiceDueDate;

  try {
    invoiceDate =
      invoice.invoiceDate !== "" ? new Date(invoice.invoiceDate) : new Date();
    invoice.invoiceDate = format(invoiceDate, dateFormat);
  } catch (error) {
    console.error("Error parsing invoiceDate:", error);
    invoiceDate = new Date();
    invoice.invoiceDate = "";
  }

  try {
    invoiceDueDate =
      invoice.invoiceDueDate !== "" ? new Date(invoice.invoiceDueDate) : null;
    if (invoiceDueDate) {
      invoice.invoiceDueDate = format(invoiceDueDate, dateFormat);
    } else {
      invoice.invoiceDueDate = "";
    }
  } catch (error) {
    console.error("Error parsing invoiceDueDate:", error);
    invoice.invoiceDueDate = "";
  }

  const handleChange = (name: keyof Invoice, value: string | number) => {
    if (name !== "productLines") {
      const newInvoice = { ...invoice };

      if (name === "logoWidth" && typeof value === "number") {
        newInvoice[name] = value;
      } else if (name !== "logoWidth" && typeof value === "string") {
        newInvoice[name] = value;
      }

      setInvoice(newInvoice);
    }
  };

  const handleProductLineChange = (
    index: number,
    name: keyof ProductLine | string,
    value: string
  ) => {
    const productLines = invoice.productLines.map((productLine, i) => {
      if (i === index) {
        const newProductLine = { ...productLine } as ProductLine;
        newProductLine[name] = value;
        return newProductLine;
      }
      return productLine;
    });

    setInvoice({ ...invoice, productLines });
  };

  const handleRemove = (i: number) => {
    const productLines = invoice.productLines.filter(
      (_: any, index: number) => index !== i
    );

    setInvoice({ ...invoice, productLines });
  };

  const handleAdd = () => {
    const productLines = [...invoice.productLines, { ...initialProductLine }];

    setInvoice({ ...invoice, productLines });
  };
  const formatNumberWithCommas = (number: string) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const calculateAmount = (quantity: string, rate: string) => {
    const quantityNumber = parseFloat(quantity.replace(/[^\d.-]/g, ""));
    const rateNumber = parseFloat(rate.replace(/[^\d.-]/g, ""));
    const amount =
      quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

    return formatNumberWithCommas(amount.toFixed(2));
  };

  useEffect(() => {
    let subTotal = 0;
    const newCustomColumns = new Set<string>();

    invoice.productLines.forEach((productLine: ProductLine) => {
      const quantityNumber = parseFloat(
        productLine.quantity.replace(/[^\d.-]/g, "")
      );
      const rateNumber = parseFloat(productLine.rate.replace(/[^\d.-]/g, ""));
      const amount =
        quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

      subTotal += amount;

      Object.keys(productLine).forEach((key) => {
        if (key !== "description" && key !== "quantity" && key !== "rate") {
          newCustomColumns.add(key);
        }
      });
    });

    setSubTotal(subTotal);
    setCustomColumns(Array.from(newCustomColumns));
  }, [invoice.productLines]);
  useEffect(() => {
    const match = invoice.taxLabel.match(/(\d+)%/);
    let taxRate = match ? parseFloat(match[1]) : 0;

    // If the tax rate is 0, leave it as 0
    const saleTax = subTotal ? (subTotal * taxRate) / 100 : 0;

    setSaleTax(saleTax);
  }, [subTotal, invoice.taxLabel]);

  useEffect(() => {
    if (onChange) {
      onChange(invoice);
    }
  }, [onChange, invoice]);

  return (
    <Document pdfMode={pdfMode}>
      {!pdfMode && (
        <button onClick={() => setOpenModal(true)}>
          Paste Data from Spreadsheet
        </button>
      )}
      <Page className="invoice-wrapper" pdfMode={pdfMode}>
        {!pdfMode && <Download data={invoice} setData={(d) => setInvoice(d)} />}

        <Modal open={openModal} onClose={() => setOpenModal(false)}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <h2>Input Data</h2>
            <TextareaAutosize
              value={inputData}
              onChange={handleInputChange}
              placeholder="Paste your data here"
              minRows={10}
              style={{ width: "100%", marginBottom: "20px" }}
            />
            <Button variant="contained" onClick={handleImportData}>
              Import Data
            </Button>
          </Box>
        </Modal>

        <div style={{ outline: "none" }}>
          <View className="flex" pdfMode={pdfMode}>
            <View className="w-50" pdfMode={pdfMode}>
              <EditableFileImage
                className="logo"
                placeholder="Your Logo"
                value={invoice.logo}
                width={invoice.logoWidth}
                pdfMode={pdfMode}
                onChangeImage={(value) => handleChange("logo", value)}
                onChangeWidth={(value) => handleChange("logoWidth", value)}
              />
              <EditableInput
                className="fs-16 bold"
                placeholder="Your Company"
                value={invoice.companyName}
                onChange={(value) => handleChange("companyName", value)}
                pdfMode={pdfMode}
              />
              <EditableInput
                placeholder="Your Name"
                value={invoice.name}
                onChange={(value) => handleChange("name", value)}
                pdfMode={pdfMode}
              />
              <EditableInput
                placeholder="Company's Address"
                value={invoice.companyAddress}
                onChange={(value) => handleChange("companyAddress", value)}
                pdfMode={pdfMode}
              />

              <EditableInput
                placeholder="City, State Zip"
                value={invoice.companyAddress2}
                onChange={(value) => handleChange("companyAddress2", value)}
                pdfMode={pdfMode}
              />
              <EditableSelect
                options={countryList}
                value={invoice.companyCountry}
                onChange={(value) => handleChange("companyCountry", value)}
                pdfMode={pdfMode}
              />
              <EditableInput
                placeholder="Vat Number"
                value={invoice.companyVatNumber}
                onChange={(value) => handleChange("companyVatNumber", value)}
                pdfMode={pdfMode}
              />
            </View>
            <View className="w-50" pdfMode={pdfMode}>
              <EditableInput
                className="fs-20 right bold"
                placeholder="Invoice"
                value={invoice.title}
                onChange={(value) => handleChange("title", value)}
                pdfMode={pdfMode}
              />
            </View>
          </View>

          <View className="flex mt-10" pdfMode={pdfMode}>
            <View className="w-55" pdfMode={pdfMode}>
              <EditableInput
                className="bold dark mb-5"
                value={invoice.billTo}
                onChange={(value) => handleChange("billTo", value)}
                pdfMode={pdfMode}
              />
              <EditableInput
                placeholder="Your Client's Name"
                value={invoice.clientName}
                onChange={(value) => handleChange("clientName", value)}
                pdfMode={pdfMode}
              />
              <EditableInput
                placeholder="Client's Address"
                value={invoice.clientAddress}
                onChange={(value) => handleChange("clientAddress", value)}
                pdfMode={pdfMode}
              />

              <EditableInput
                placeholder="City, State Zip"
                value={invoice.clientAddress2}
                onChange={(value) => handleChange("clientAddress2", value)}
                pdfMode={pdfMode}
              />
              <EditableSelect
                options={countryList}
                value={invoice.clientCountry}
                onChange={(value) => handleChange("clientCountry", value)}
                pdfMode={pdfMode}
              />
              <EditableInput
                placeholder="Vat Number"
                value={invoice.clientVatNumber}
                onChange={(value) => handleChange("clientVatNumber", value)}
                pdfMode={pdfMode}
              />
            </View>
            <View className="w-45" pdfMode={pdfMode}>
              <View className="flex mb-5" pdfMode={pdfMode}>
                <View className="w-40" pdfMode={pdfMode}>
                  <EditableInput
                    className="bold"
                    value={invoice.invoiceTitleLabel}
                    onChange={(value) =>
                      handleChange("invoiceTitleLabel", value)
                    }
                    pdfMode={pdfMode}
                  />
                </View>
                <View className="w-60" pdfMode={pdfMode}>
                  <EditableInput
                    placeholder="INV-12"
                    value={invoice.invoiceTitle}
                    onChange={(value) => handleChange("invoiceTitle", value)}
                    pdfMode={pdfMode}
                  />
                </View>
              </View>
              <View className="flex mb-5" pdfMode={pdfMode}>
                <View className="w-40" pdfMode={pdfMode}>
                  <EditableInput
                    className="bold"
                    value={invoice.invoiceDateLabel}
                    onChange={(value) =>
                      handleChange("invoiceDateLabel", value)
                    }
                    pdfMode={pdfMode}
                  />
                </View>
                <View className="w-60" pdfMode={pdfMode}>
                  <EditableCalendarInput
                    value={format(invoiceDate, dateFormat)}
                    selected={invoiceDate}
                    onChange={(date) =>
                      handleChange(
                        "invoiceDate",
                        date && !Array.isArray(date)
                          ? format(date, dateFormat)
                          : ""
                      )
                    }
                    pdfMode={pdfMode}
                  />
                </View>
              </View>
              <View className="flex mb-5" pdfMode={pdfMode}>
                <View className="w-40" pdfMode={pdfMode}>
                  <EditableInput
                    className="bold"
                    value={invoice.invoiceDueDateLabel}
                    onChange={(value) =>
                      handleChange("invoiceDueDateLabel", value)
                    }
                    pdfMode={pdfMode}
                  />
                </View>
                <View className="w-60" pdfMode={pdfMode}>
                  <EditableCalendarInput
                    value={
                      invoiceDueDate ? format(invoiceDueDate, dateFormat) : ""
                    }
                    selected={invoiceDueDate}
                    onChange={(date) =>
                      handleChange(
                        "invoiceDueDate",
                        date
                          ? !Array.isArray(date)
                            ? format(date, dateFormat)
                            : ""
                          : ""
                      )
                    }
                    pdfMode={pdfMode}
                  />
                </View>
              </View>
            </View>
          </View>

          <View className="mt-10 bg-dark flex" pdfMode={pdfMode}>
            <View className="w-auto p-4-8" pdfMode={pdfMode}>
              <EditableInput
                className="white bold"
                value={invoice.productLineDescription}
                onChange={(value) =>
                  handleChange("productLineDescription", value)
                }
                pdfMode={pdfMode}
              />
            </View>
            {customColumns.map((column) => (
              <View key={column} className="w-auto p-4-8" pdfMode={pdfMode}>
                <EditableInput
                  className="white bold right"
                  placeholder={column}
                  value={column}
                  onChange={(value) => {
                    const newColumns = customColumns.map((col) =>
                      col === column ? value : col
                    );
                    setCustomColumns(newColumns);
                  }}
                  pdfMode={pdfMode}
                />
                {!pdfMode && (
                  <button
                    className="link column__remove"
                    aria-label="Remove Column"
                    title="Remove Column"
                    onClick={() => handleDeleteCustomColumn(column)}
                  >
                    <span className="icon icon-remove bg-red"></span>
                  </button>
                )}
              </View>
            ))}
            <View className="w-17 p-4-8" pdfMode={pdfMode}>
              <EditableInput
                className="white bold right"
                value={invoice.productLineQuantity}
                onChange={(value) => handleChange("productLineQuantity", value)}
                pdfMode={pdfMode}
              />
            </View>
            <View className="w-17 p-4-8" pdfMode={pdfMode}>
              <EditableInput
                className="white bold right"
                value={invoice.productLineQuantityRate}
                onChange={(value) =>
                  handleChange("productLineQuantityRate", value)
                }
                pdfMode={pdfMode}
              />
            </View>
            <View className="w-18 p-4-8" pdfMode={pdfMode}>
              <EditableInput
                className="white bold right"
                value={invoice.productLineQuantityAmount}
                onChange={(value) =>
                  handleChange("productLineQuantityAmount", value)
                }
                pdfMode={pdfMode}
              />
            </View>
          </View>

          {invoice.productLines.map(
            (productLine: ProductLine, i: Key | null | undefined) => {
              return (
                <View key={i} className="row flex" pdfMode={pdfMode}>
                  <View className="w-auto p-4-8 pb-10" pdfMode={pdfMode}>
                    <EditableTextarea
                      className="dark"
                      rows={2}
                      placeholder="Enter item name/description"
                      value={productLine.description}
                      onChange={(value) =>
                        handleProductLineChange(
                          i as number,
                          "description",
                          value
                        )
                      }
                      pdfMode={pdfMode}
                    />
                  </View>
                  {customColumns.map((column) => (
                    <View
                      key={column}
                      className="w-auto p-4-8 pb-10"
                      pdfMode={pdfMode}
                    >
                      <EditableInput
                        className="dark right"
                        placeholder={column}
                        value={productLine[column] || ""}
                        onChange={(value) =>
                          handleProductLineChange(i as number, column, value)
                        }
                        pdfMode={pdfMode}
                      />
                    </View>
                  ))}
                  <View className="w-17 p-4-8 pb-10" pdfMode={pdfMode}>
                    <EditableInput
                      className="dark right"
                      value={productLine.quantity}
                      onChange={(value) =>
                        handleProductLineChange(i as number, "quantity", value)
                      }
                      pdfMode={pdfMode}
                    />
                  </View>
                  <View className="w-17 p-4-8 pb-10" pdfMode={pdfMode}>
                    <EditableInput
                      className="dark right"
                      value={productLine.rate}
                      onChange={(value) =>
                        handleProductLineChange(i as number, "rate", value)
                      }
                      pdfMode={pdfMode}
                    />
                  </View>
                  <View className="w-18 p-4-8 pb-10" pdfMode={pdfMode}>
                    <Text className="dark right" pdfMode={pdfMode}>
                      {calculateAmount(
                        productLine.quantity ?? "",
                        productLine.rate ?? ""
                      )}
                    </Text>
                  </View>

                  {!pdfMode && (
                    <button
                      className="link row__remove"
                      aria-label="Remove Row"
                      title="Remove Row"
                      onClick={() => handleRemove(i as number)}
                    >
                      <span className="icon icon-remove bg-red"></span>
                    </button>
                  )}
                </View>
              );
            }
          )}

          <View className="flex" pdfMode={pdfMode}>
            <View className="w-60 mt-10" pdfMode={pdfMode}>
              <View className="w-120 mt-10" pdfMode={pdfMode}>
                {" "}
                <EditableInput
                  className="bold "
                  value={invoice.extraLabel}
                  onChange={(value) => handleChange("extraLabel", value)}
                  pdfMode={pdfMode}
                />
                <EditableInput
                  value={invoice.extra}
                  onChange={(value) => handleChange("extra", value)}
                  pdfMode={pdfMode}
                />
              </View>
              {!pdfMode && (
                <button className="link" onClick={handleAdd}>
                  <span className="icon icon-add bg-green mr-10"></span>
                  Add Line Item
                </button>
              )}
              {!pdfMode && (
                <button className="link" onClick={handleAddCustomColumn}>
                  <span className="icon icon-add bg-green mr-10"></span>
                  Add Custom Column
                </button>
              )}
            </View>
            {/* <View className="w-50 mt-10" pdfMode={pdfMode}>
              <EditableInput
                className="bold "
                value={invoice.extraLabel}
                onChange={(value) => handleChange("extraLabel", value)}
                pdfMode={pdfMode}
              />
              <EditableInput
                value={invoice.extra}
                onChange={(value) => handleChange("extra", value)}
                pdfMode={pdfMode}
              />
            </View> */}

            <View className="w-40 mt-10" pdfMode={pdfMode}>
              <View className="flex" pdfMode={pdfMode}>
                <View className="w-40 p-5" pdfMode={pdfMode}>
                  <EditableInput
                    value={invoice.subTotalLabel}
                    onChange={(value) => handleChange("subTotalLabel", value)}
                    pdfMode={pdfMode}
                  />
                </View>
                <View className="w-60 p-5" pdfMode={pdfMode}>
                  <Text className="right bold dark" pdfMode={pdfMode}>
                    {subTotal
                      ? formatNumberWithCommas(subTotal.toFixed(2))
                      : "0.00"}
                  </Text>
                </View>
              </View>
              <View className="flex" pdfMode={pdfMode}>
                <View className="w-40 p-5" pdfMode={pdfMode}>
                  <EditableInput
                    value={invoice.taxLabel}
                    onChange={(value) => handleChange("taxLabel", value)}
                    pdfMode={pdfMode}
                  />
                </View>
                <View className="w-60 p-5" pdfMode={pdfMode}>
                  <Text className="right bold dark" pdfMode={pdfMode}>
                    {saleTax
                      ? formatNumberWithCommas(saleTax.toFixed(2))
                      : "0.00"}
                  </Text>
                </View>
              </View>
              <View className="flex bg-gray p-5" pdfMode={pdfMode}>
                <View className="w-40 p-5" pdfMode={pdfMode}>
                  <EditableInput
                    className="bold"
                    value={invoice.totalLabel}
                    onChange={(value) => handleChange("totalLabel", value)}
                    pdfMode={pdfMode}
                  />
                </View>
                <View className="w-60 p-5 flex " pdfMode={pdfMode}>
                  <EditableInput
                    className="dark bold right ml-30"
                    value={invoice.currency}
                    onChange={(value) => handleChange("currency", value)}
                    pdfMode={pdfMode}
                  />
                  <Text className="right bold dark" pdfMode={pdfMode}>
                    {formatNumberWithCommas(
                      (subTotal
                        ? saleTax
                          ? subTotal + saleTax
                          : subTotal
                        : 0
                      ).toFixed(2)
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </View>

          <View className="mt-10" pdfMode={pdfMode}>
            <EditableInput
              className="bold w-100"
              value={invoice.notesLabel}
              onChange={(value) => handleChange("notesLabel", value)}
              pdfMode={pdfMode}
            />
            <EditableTextarea
              className="w-100"
              rows={2}
              value={invoice.notes}
              onChange={(value) => handleChange("notes", value)}
              pdfMode={pdfMode}
            />
          </View>
          <View className="mt-10" pdfMode={pdfMode}>
            <EditableInput
              className="bold w-100"
              value={invoice.termLabel}
              onChange={(value) => handleChange("termLabel", value)}
              pdfMode={pdfMode}
            />
            <EditableTextarea
              className="w-100"
              rows={2}
              value={invoice.term}
              onChange={(value) => handleChange("term", value)}
              pdfMode={pdfMode}
            />
          </View>
        </div>
      </Page>
    </Document>
  );
};

export default InvoicePage;
