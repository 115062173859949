import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

// Define keyframes for the logo fade-in and fade-out animation
const logoFadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const logoFadeOut = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
`;

const backgroundFadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const Overlay = styled.div<{ fadeOut: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #024798;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; // Ensures the loading screen is above everything
  animation: ${(props) => (props.fadeOut ? backgroundFadeOut : "none")} 2s ease
    forwards;
  pointer-events: none; // Prevents interaction with the overlay
`;

const Logo = styled.img<{ fadeOut: boolean }>`
  width: 200px;
  height: auto;
  animation: ${(props) => (props.fadeOut ? logoFadeOut : logoFadeIn)} 2s ease
    forwards;
`;

const LoadingScreen: React.FC<{ onFinish: () => void }> = ({ onFinish }) => {
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFadeOut(true);
      setTimeout(onFinish, 2000); // Wait for fade out animation to finish
    }, 2000); // Duration before starting to fade out
    return () => clearTimeout(timer);
  }, [onFinish]);

  return (
    <Overlay fadeOut={fadeOut}>
      <Logo src="assets/invo.jpg" alt="INVO Logo" fadeOut={fadeOut} />
    </Overlay>
  );
};

export default LoadingScreen;
