import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css"; // Import styles for the zoom component
import { Invoice } from "../Invoices";
import { collection, getDocs } from "firebase/firestore";
import { auth, db } from "../../firebase";
import moment from "moment";

interface EditInvoiceModalProps {
  open: boolean;
  onClose: () => void;
  invoice: Invoice | null;
  onSave: (
    invoice: Invoice,
    newName: string,
    newCategory: string,
    newInvoiceNumber: number,

    newDate: string,
    newSubtotal: number,
    newVatTotal: number,
    newTotal: number,
    newClientName: string
  ) => void;
}

const EditInvoiceModal: React.FC<EditInvoiceModalProps> = ({
  open,
  onClose,
  invoice,
  onSave,
}) => {
  const [newName, setNewName] = useState<string>("");
  const [newInvoiceNumber, setNewInvoiceNumber] = useState<number>(0);
  const [newCategory, setNewCategory] = useState<string>("");
  const [newDate, setNewDate] = useState<string>("");
  const [newSubtotal, setNewSubtotal] = useState<string>(""); // Changed to string for TextField
  const [newVatTotal, setNewVatTotal] = useState<string>(""); // Changed to string for TextField
  const [newTotal, setNewTotal] = useState<string>(""); // Changed to string for TextField
  const [newClientName, setNewClientName] = useState<string>("");
  const [clients, setClients] = useState<string[]>([]);

  useEffect(() => {
    if (invoice) {
      setNewName(invoice.name);
      setNewInvoiceNumber(invoice.invoiceNumber);
      setNewDate(
        invoice.date
          ? moment(invoice.date, "DD/MM/YYYY").format("YYYY-MM-DD")
          : ""
      ); // Format date for input
      setNewSubtotal(
        invoice.subtotal !== null ? invoice.subtotal.toString() : ""
      ); // Set subtotal
      setNewVatTotal(
        invoice.vatTotal !== null ? invoice.vatTotal.toString() : ""
      ); // Set vatTotal
      setNewTotal(invoice.total !== null ? invoice.total.toString() : ""); // Set total
      setNewClientName(invoice.clientName || ""); // Set client name
      setNewCategory(invoice.category || ""); // Set category
    } else {
      setNewName("");
      setNewInvoiceNumber(0);
      setNewDate("");
      setNewCategory("");
      setNewSubtotal("");
      setNewVatTotal("");
      setNewTotal("");
      setNewClientName("");
    }
  }, [invoice]);

  useEffect(() => {
    const fetchClients = async () => {
      const user = auth.currentUser;
      if (!user) return;
      const userId = user.uid;
      const q = collection(db, "clients", userId, "userClients");
      const querySnapshot = await getDocs(q);
      setClients(querySnapshot.docs.map((doc) => doc.data().name));
    };

    fetchClients();
  }, []);

  const calculateVatFromSubtotal = (subtotal: number) => subtotal * 0.18;
  const calculateTotalFromSubtotal = (subtotal: number) => subtotal * 1.18;
  const calculateSubtotalFromTotal = (total: number) => total / 1.18;
  const calculateVatFromTotal = (total: number) =>
    total - calculateSubtotalFromTotal(total);

  const handleSubtotalChange = (value: string) => {
    const subtotal = parseFloat(value) || 0;
    setNewSubtotal(value);
    if (newVatTotal === "") {
      setNewVatTotal(calculateVatFromSubtotal(subtotal).toFixed(2));
    }
    if (newTotal === "") {
      setNewTotal(calculateTotalFromSubtotal(subtotal).toFixed(2));
    }
  };

  const handleTotalChange = (value: string) => {
    const total = parseFloat(value) || 0;
    setNewTotal(value);
    setNewSubtotal(calculateSubtotalFromTotal(total).toFixed(2));
    setNewVatTotal(calculateVatFromTotal(total).toFixed(2));
  };

  const handleSave = () => {
    if (invoice) {
      let date = moment(newDate, "YYYY-MM-DD").format("DD/MM/YYYY");

      onSave(
        invoice,
        newName || `Invoice_${newInvoiceNumber}`,
        newCategory,
        newInvoiceNumber,
        date,
        parseFloat(newSubtotal) || 0,
        parseFloat(newVatTotal) || 0,
        parseFloat(newTotal) || 0,
        newClientName
      );
    }
  };

  if (!invoice) return null;

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="edit-invoice-modal-title"
      aria-describedby="edit-invoice-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "90vh",
          overflowY: "auto",
        }}
      >
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography id="edit-invoice-modal-title" variant="h6" component="h2">
            Edit Invoice
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Autocomplete
          options={clients}
          freeSolo
          value={newClientName}
          onChange={(event, newValue) => {
            setNewClientName(newValue || "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Client Name"
              variant="outlined"
              fullWidth
              margin="normal"
              onChange={(e) => setNewClientName(e.target.value)}
            />
          )}
        />
        <TextField
          label="Category"
          variant="outlined"
          fullWidth
          margin="normal"
          value={newCategory}
          onChange={(e) => setNewCategory(e.target.value)}
        />
        <TextField
          label="Invoice Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
        <TextField
          label="Invoice Number"
          variant="outlined"
          fullWidth
          margin="normal"
          value={newInvoiceNumber}
          onChange={(e) => setNewInvoiceNumber(parseInt(e.target.value))}
        />
        <TextField
          label="Date"
          type="date"
          variant="outlined"
          fullWidth
          margin="normal"
          value={newDate}
          onChange={(e) => setNewDate(e.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          placeholder="DD/MM/YYYY"
        />
        <TextField
          label="Subtotal"
          variant="outlined"
          fullWidth
          margin="normal"
          value={newSubtotal}
          onChange={(e) => handleSubtotalChange(e.target.value)}
        />
        <TextField
          label="VAT Total"
          variant="outlined"
          fullWidth
          margin="normal"
          value={newVatTotal}
          onChange={(e) => setNewVatTotal(e.target.value)}
          placeholder={`VAT: ${(parseFloat(newSubtotal) * 0.18).toFixed(2)}`}
        />
        <TextField
          label="Total"
          variant="outlined"
          fullWidth
          margin="normal"
          value={newTotal}
          onChange={(e) => handleTotalChange(e.target.value)}
          placeholder={`Total: ${(parseFloat(newSubtotal) * 1.18).toFixed(2)}`}
        />
        <Box mt={4}>
          <Zoom>
            <img
              src={invoice.fileUrl}
              alt={invoice.name}
              className="w-full h-auto"
            />
          </Zoom>
        </Box>
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditInvoiceModal;
