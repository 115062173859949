import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../../firebase"; // Adjust the paths based on your structure

const SignIn: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const getErrorMessage = (errorCode: string) => {
    console.log(errorCode);

    switch (errorCode) {
      case "auth/invalid-email":
        return "Invalid email address.";
      case "auth/user-disabled":
        return "This user has been disabled.";
      case "auth/user-not-found":
        return "No user found with this email.";
      case "auth/wrong-password":
        return "Incorrect password.";
      case "auth/invalid-credential":
        return "Incorrect email address or password.";
      case "auth/too-many-requests":
        return "Too many requests. Please try again later.";
      default:
        return "An unknown error occurred. Please try again.";
    }
  };

  const signIn = async () => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (!userDoc.exists()) {
        // If the user document does not exist, create it
        await setDoc(userDocRef, {
          firstName: user.displayName ? user.displayName.split(" ")[0] : "",
          lastName: user.displayName
            ? user.displayName.split(" ")[1] || ""
            : "",
          email: user.email,
          phoneNumber: "", // Assuming phone number isn't available here
          apiKey: "", // Initialize the API key as an empty string
          companyName: "", // Initialize the company name as an empty string
        });

        console.log("User document created.");
      } else {
        console.log("User document already exists.");
      }

      Swal.fire("Success", "Signed in successfully", "success");
    } catch (error: any) {
      Swal.fire("Error", getErrorMessage(error.code), "error");
    }
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl mb-4">Sign In</h2>
      <input
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
        className="border p-2 mb-4 w-full"
      />
      <div className="relative mb-4">
        <input
          type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
          className="border p-2 w-full"
        />
        <div
          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
          onClick={() => setShowPassword(!showPassword)}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>
      <button
        onClick={signIn}
        className="bg-blue-500 text-white p-2 w-full mb-2"
      >
        Sign In
      </button>
      <div className="flex justify-between mt-4">
        <Link to="/forgot-password" className="text-blue-500">
          Forgot Password?
        </Link>
        <Link to="/signup" className="text-blue-500">
          Don't have an account? Sign Up
        </Link>
      </div>
    </div>
  );
};

export default SignIn;
